<template>
    <div id="margin-dialog-body">
        <v-card class="mb-3">
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemAviso"
                @fecharSnackbar="snackbar = false"
            />
            <v-container>
                <form ref="ofertaForm">
                    <validation-observer ref="observer">
                        <form @submit.prevent="submit">
                            <v-card-title
                                class="
                                    primary--text
                                    font-weight-black
                                    justify-center
                                    title-2
                                    mt-5
                                "
                            >
                                Detalhes de pagamento:</v-card-title
                            >
                            <v-card class="grey lighten-4 ma-3 pa-3">
                                <v-row>
                                    <v-col sm="3" md="5" class="ma-auto mt-3">
                                        <div v-show="true">
                                            <v-row>
                                                <v-card
                                                    class="pa-3 ma-3"
                                                    shaped
                                                >
                                                    <v-col
                                                        class="d-flex justify-end align-center"
                                                    >
                                                        <v-icon
                                                            class="
                                                                primary--text
                                                                ma-auto
                                                                pa-n3
                                                                ma-4
                                                            "
                                                            size="100"
                                                        >
                                                            mdi-sale
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col sm="12">
                                                        <v-card-text
                                                            class="
                                                                display-1
                                                                primary--text
                                                                font-weight-black
                                                            "
                                                        >
                                                            Quero oferecer uma
                                                            porcentagem de
                                                            desconto
                                                        </v-card-text>

                                                        <v-text-field
                                                            dense
                                                            solo
                                                            class="
                                                                my-4
                                                                display-1
                                                                font-weight-black
                                                            "
                                                            rounded
                                                            v-model="
                                                                computedPorcentagemDescontoFormated
                                                            "
                                                            :disabled="
                                                                oferta.desconto ||
                                                                    activeIdOffer
                                                            "
                                                            prefix="%"
                                                            label="Digite Aqui"
                                                            @input="
                                                                descontoCheckPorcentagem
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card>
                                            </v-row>
                                            <v-card-text
                                                class="
                                                    title-1
                                                    grey--text
                                                    font-weight-regular
                                                "
                                            >
                                                Selecione essa opção para
                                                oferecer o desconto em formato
                                                de porcentagem (%). Ao
                                                selecionar essa opção não será
                                                possivel selecionar desconto em
                                                valor fixo (R$).
                                            </v-card-text>
                                        </div>
                                        <div v-if="false">
                                            <v-card class="pa-3 ma-3" shaped>
                                                <v-col>
                                                    <v-icon
                                                        class="
                                                            grey--text
                                                            ma-auto
                                                            pa-n3
                                                            ma-4
                                                        "
                                                        size="100"
                                                    >
                                                        report
                                                    </v-icon>
                                                </v-col>
                                                <v-col>
                                                    <v-card-text
                                                        class="
                                                            display-1
                                                            grey--text
                                                            font-weight-light
                                                        "
                                                    >
                                                        Informe o valor do
                                                        desconto em Reais (R$).
                                                    </v-card-text>
                                                    <v-card-text
                                                        class="
                                                            subtitle
                                                            grey--text
                                                            font-weight-light
                                                        "
                                                    >
                                                        Preencha os dados ao
                                                        lado.
                                                    </v-card-text>
                                                </v-col>
                                            </v-card>
                                        </div>
                                    </v-col>
                                    <v-col sm="3" md="5" class="ma-auto mt-3">
                                        <div v-if="true">
                                            <v-row>
                                                <v-card
                                                    class="pa-3 ma-3"
                                                    shaped
                                                >
                                                    <v-col
                                                        class="d-flex justify-end align-center"
                                                    >
                                                        <v-icon
                                                            class="
                                                                primary--text
                                                                ma-auto
                                                                pa-n3
                                                                ma-4
                                                            "
                                                            size="100"
                                                        >
                                                            mdi-cash-multiple
                                                        </v-icon>
                                                    </v-col>
                                                    <v-col>
                                                        <v-card-text
                                                            class="
                                                                display-1
                                                                primary--text
                                                                font-weight-black
                                                            "
                                                        >
                                                            Quero oferecer um
                                                            valor fixo total de
                                                            desconto
                                                        </v-card-text>

                                                        <v-text-field
                                                            dense
                                                            solo
                                                            rounded
                                                            class="
                                                                my-4
                                                                display-1
                                                                font-weight-black
                                                            "
                                                            v-model="
                                                                computedDescontoFormated
                                                            "
                                                            :disabled="
                                                                oferta.desconto_porcentagem ||
                                                                    activeIdOffer
                                                            "
                                                            label="Digite Aqui"
                                                            prefix="R$"
                                                            @input="
                                                                descontoCheck
                                                            "
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-card>
                                                <v-card-text
                                                    class="
                                                        title-1
                                                        grey--text
                                                        font-weight-regular
                                                    "
                                                >
                                                    Selecione essa opção para
                                                    oferecer o desconto em
                                                    formato de valor Fixo (R$).
                                                    Ao selecionar essa opção não
                                                    será possivel selecionar
                                                    desconto em formato
                                                    porcentagem (%).
                                                </v-card-text>
                                            </v-row>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card>
                            <v-card-actions class="justify-center">
                                <v-btn
                                    v-if="!activeIdOffer"
                                    @click="habilitaSecao1"
                                    rounded
                                    color="primary"
                                    dark
                                >
                                    Próximo Passo
                                </v-btn>
                            </v-card-actions>
                            <div v-show="passo1">
                                <v-row class="ma-3 pa-3">
                                    <v-col cols="12" sm="3" md="6">
                                        <v-card
                                            class="ma-3 pa-6 grey lighten-5"
                                        >
                                            <v-row>
                                                <v-icon
                                                    class="grey--text ml-5"
                                                    size="40"
                                                >
                                                    credit_card
                                                </v-icon>

                                                <v-card-title
                                                    class="grey--text"
                                                >
                                                    Desejo Oferecer pagamento
                                                    via Cartão
                                                </v-card-title>

                                                <v-switch
                                                    solo
                                                    v-model="oferta.cartao"
                                                    label="Ativar"
                                                    required
                                                    disabled
                                                    class="white--text ma-auto"
                                                    @change="limparCartao"
                                                ></v-switch>
                                            </v-row>
                                            <div v-show="oferta.cartao">
                                                <v-text-field
                                                    solo
                                                    prefix="x"
                                                    v-model="
                                                        oferta.parcela_cartao
                                                    "
                                                    :error-messages="
                                                        errorMessages
                                                    "
                                                    label="Máximo de Parcelas"
                                                    type="number"
                                                    :disabled="
                                                        !oferta.cartao ||
                                                            activeIdOffer
                                                    "
                                                    required
                                                ></v-text-field>
                                                <v-text-field
                                                    solo
                                                    prefix="%"
                                                    v-model="
                                                        computedJurosCartaoFormated
                                                    "
                                                    label="Juros por parcela"
                                                    :disabled="
                                                        !oferta.cartao ||
                                                            activeIdOffer
                                                    "
                                                ></v-text-field>
                                            </div>
                                        </v-card>
                                        <v-card-text
                                            class="
                                                title-1
                                                grey--text
                                                font-weight-regular
                                            "
                                        >
                                            Ao ativar essa opção estará
                                            habilitando a opção de pagamento via
                                            Cartão de Crédito, podendo
                                            configurar a quantidade máxima de
                                            parcelas e juros.
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="12" sm="3" md="6">
                                        <v-card
                                            class="ma-3 pa-6 grey lighten-5"
                                        >
                                            <v-row>
                                                <v-icon
                                                    class="primary--text ml-5"
                                                    size="40"
                                                >
                                                    account_balance_wallet
                                                </v-icon>
                                                <v-card-title
                                                    class="primary--text"
                                                >
                                                    Desejo Oferecer pagamento
                                                    via Boleto
                                                </v-card-title>
                                                <v-switch
                                                    solo
                                                    v-model="oferta.boleto"
                                                    label="Ativar"
                                                    required
                                                    :disabled="activeIdOffer"
                                                    class="white--text ma-auto"
                                                    @change="limparBoleto"
                                                ></v-switch>
                                            </v-row>
                                            <div v-show="oferta.boleto">
                                                <v-text-field
                                                    solo
                                                    prefix="x"
                                                    v-model="
                                                        oferta.parcela_boleto
                                                    "
                                                    :error-messages="
                                                        errorMessages
                                                    "
                                                    :disabled="
                                                        !oferta.boleto ||
                                                            activeIdOffer
                                                    "
                                                    label="Máximo de Parcelas"
                                                    type="number"
                                                    required
                                                ></v-text-field>
                                                <v-text-field
                                                    solo
                                                    prefix="%"
                                                    v-model="
                                                        computedJurosBoletoFormated
                                                    "
                                                    :error-messages="
                                                        errorMessages
                                                    "
                                                    :disabled="
                                                        !oferta.boleto ||
                                                            activeIdOffer
                                                    "
                                                    label="Juros por boleto"
                                                    required
                                                ></v-text-field>
                                            </div>
                                        </v-card>
                                        <v-card-text
                                            class="
                                                title-1
                                                grey--text
                                                font-weight-regular
                                            "
                                        >
                                            Ao ativar essa opção estará
                                            habilitando a opção de pagamento via
                                            Boleto Bancário, podendo configurar
                                            a quantidade máxima de parcelas e
                                            juros.
                                        </v-card-text>
                                    </v-col>
                                </v-row>
                                <v-card-actions class="justify-center">
                                    <v-btn
                                        @click="habilitaSecao2"
                                        rounded
                                        color="primary"
                                        v-if="!activeIdOffer"
                                        dark
                                    >
                                        Próximo Passo
                                    </v-btn>
                                </v-card-actions>
                            </div>
                            <div v-show="passo2">
                                <v-card-title
                                    class="
                                        grey--text
                                        font-weight-black
                                        justify-center
                                        title-2
                                    "
                                >
                                    <v-icon> keyboard_arrow_down </v-icon>
                                    Passo 3: Definir Prazos da
                                    Oferta:</v-card-title
                                >
                                <v-card class="ma-6 pa-3 white">
                                    <v-row>
                                        <v-col
                                            cols="8"
                                            sm="12"
                                            md="1"
                                            class="mx-auto my-auto"
                                        >
                                            <v-icon
                                                class="primary--text d-block"
                                                size="100"
                                            >
                                                date_range
                                            </v-icon>
                                        </v-col>
                                        <v-col
                                            cols="8"
                                            sm="12"
                                            md="4"
                                            class="mx-auto my-auto"
                                        >
                                            <v-card-text
                                                class="
                                                    primary--text
                                                    display-1
                                                    font-weight-black
                                                    d-block
                                                "
                                            >
                                                Início e Encerramento da Oferta
                                            </v-card-text>
                                        </v-col>
                                        <v-col cols="8" sm="12" md="4">
                                            <div>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-menu
                                                        ref="menu1"
                                                        v-model="menu1"
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :return-value.sync="
                                                            oferta.data_inicio
                                                        "
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                v-model="
                                                                    computedDateFormattedInicio
                                                                "
                                                                label="Defina o Início da Oferta"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                clearable
                                                                :disabled="
                                                                    activeIdOffer
                                                                "
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="
                                                                oferta.data_inicio
                                                            "
                                                            no-title
                                                            scrollable
                                                            locale="pt-br"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    menu1 = false
                                                                "
                                                            >
                                                                Cancel
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    $refs.menu1.save(
                                                                        oferta.data_inicio
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </div>
                                            <div>
                                                <v-col
                                                    cols="12"
                                                    sm="12"
                                                    md="12"
                                                >
                                                    <v-menu
                                                        ref="menu2"
                                                        v-model="menu2"
                                                        :close-on-content-click="
                                                            false
                                                        "
                                                        :return-value.sync="
                                                            oferta.data_fim
                                                        "
                                                        transition="scale-transition"
                                                        offset-y
                                                        min-width="290px"
                                                    >
                                                        <template
                                                            v-slot:activator="{
                                                                on,
                                                                attrs
                                                            }"
                                                        >
                                                            <v-text-field
                                                                v-model="
                                                                    computedDateFormattedFim
                                                                "
                                                                label="Defina o Encerramento"
                                                                prepend-icon="mdi-calendar"
                                                                readonly
                                                                v-bind="attrs"
                                                                v-on="on"
                                                                persistent-hint
                                                                clearable
                                                                :disabled="
                                                                    activeIdOffer
                                                                "
                                                            ></v-text-field>
                                                        </template>
                                                        <v-date-picker
                                                            v-model="
                                                                oferta.data_fim
                                                            "
                                                            no-title
                                                            scrollable
                                                            locale="pt-br"
                                                        >
                                                            <v-spacer></v-spacer>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    menu2 = false
                                                                "
                                                            >
                                                                Cancelar
                                                            </v-btn>
                                                            <v-btn
                                                                text
                                                                color="primary"
                                                                @click="
                                                                    $refs.menu2.save(
                                                                        oferta.data_fim
                                                                    )
                                                                "
                                                            >
                                                                OK
                                                            </v-btn>
                                                        </v-date-picker>
                                                    </v-menu>
                                                </v-col>
                                            </div>
                                        </v-col>
                                        <v-col
                                            cols="12"
                                            sm="3"
                                            md="3"
                                            class="mx-auto my-auto"
                                        >
                                        </v-col>
                                    </v-row>
                                </v-card>

                                <v-card-title
                                    class="
                                        grey--text
                                        font-weight-black
                                        justify-center
                                        title-2
                                    "
                                >
                                    <v-icon> keyboard_arrow_down </v-icon>
                                    Passo 4: Ative a Renegociação:</v-card-title
                                >
                                <v-col
                                    cols="12"
                                    sm="3"
                                    md="12"
                                    class="mx-auto my-auto"
                                >
                                    <v-card class="ma-3 pa-3 green">
                                        <v-row class="mx-auto my-auto">
                                            <v-col sm="3" md="8">
                                                <v-card-title
                                                    class="
                                                        title-2
                                                        font-weight-black
                                                        white--text
                                                    "
                                                >
                                                    Status da Oferta<v-icon
                                                        size="40"
                                                        class="white--text"
                                                    >
                                                        double_arrow
                                                    </v-icon>
                                                </v-card-title>
                                            </v-col>
                                            <v-col
                                                sm="3"
                                                md="4"
                                                class="mx-auto my-auto"
                                            >
                                                <v-card
                                                    v-if="!activeIdOffer"
                                                    class="ma-2 pa-2"
                                                >
                                                    <v-switch
                                                        color="success"
                                                        inset
                                                        v-model="oferta.status"
                                                        label="Desativar/Ativar Oferta"
                                                        required
                                                        v-if="!activeIdOffer"
                                                        class="mx-auto my-auto"
                                                    ></v-switch>
                                                </v-card>
                                                <v-card
                                                    v-if="activeIdOffer"
                                                    class="ma-2 pa-2"
                                                >
                                                    <v-btn
                                                        v-if="activeIdOffer"
                                                        color="error"
                                                        width="100%"
                                                        @click="
                                                            modalDesabilitaOferta
                                                        "
                                                    >
                                                        Desabilitar Oferta
                                                    </v-btn>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-col>
                            </div>
                        </form>
                    </validation-observer>
                </form>
                <v-dialog
                    v-if="modalTemCerteza"
                    v-model="modalTemCerteza"
                    max-width="800px"
                    scrollable
                    color="background"
                    transition="dialog-bottom-transition"
                >
                    <!-- Informações sobre a Campanha -->
                    <v-card>
                        <v-card-title class="pa-0">
                            <v-toolbar flat dark color="primary">
                                <v-btn icon dark @click="modalTeste = false">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>
                                    Desabilitar Oferta
                                </v-toolbar-title>
                            </v-toolbar>
                        </v-card-title>
                        <v-divider />
                        <v-card-text class="px-8 py-4">
                            <v-row class="d-flex text-center">
                                <v-col lg="2"></v-col>
                                <v-col lg="8">
                                    <v-card>
                                        <v-icon
                                            color="primary"
                                            size="500%"
                                            class="mt-5"
                                        >
                                            mdi-information
                                        </v-icon>
                                        <v-card-text
                                            class="title primary--text"
                                        >
                                            Tem certeza que deseja desabilitar?
                                        </v-card-text>
                                        <v-card-text class="title">
                                            Ao desabilitar a oferta, os
                                            devedores não poderão mais utilizar
                                            ela para realizar pagamentos de
                                            títulos.
                                        </v-card-text>
                                        <v-card-text>
                                            Em caso de dúvidas, entre em contato
                                            com nosso suporte através do e-mail:
                                            suporte@recuperi.com.br
                                        </v-card-text>
                                        <v-card
                                            class="d-flex justify-center"
                                            style="display:flex; align-content: center; justify-content: center; text-align: center"
                                        >
                                            <v-checkbox
                                                v-model="liConcordo"
                                                :label="
                                                    `Li e concordo com as condições acima.`
                                                "
                                            ></v-checkbox>
                                        </v-card>
                                    </v-card>
                                </v-col>
                                <v-col lg="2"></v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider />
                        <v-card-actions
                            style="display: flex; justify-content: end; padding: 8px 48px 8px 16px"
                        >
                            <v-btn
                                class="ml-5"
                                color="success white--text"
                                width="120"
                                :loading="loading"
                                :disabled="!liConcordo"
                                @click="desabilitarOferta"
                            >
                                Confirmar
                            </v-btn>
                            <v-btn
                                class="ml-5"
                                color="grey white--text"
                                width="120"
                                :loading="loading"
                                @click="modalTemCerteza = false"
                            >
                                Cancelar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                    <!-- fim das informações sobre a campanha --------------------- -->
                </v-dialog>
            </v-container>
        </v-card>
    </div>
</template>

<script>
import * as Formatter from '@/helpers/Formatter';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, ValidationObserver, setInteractionMode } from 'vee-validate';
import OfertaService from '@/services/OfertaService';
import Vue from 'vue';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'DetalheOferta',
    props: {
        oferta: {
            type: Object,
            required: true
        },
        titulo: {
            type: Object,
            required: false
        },
        activeIdOffer: {
            type: Boolean,
            required: false,
            default: null
        },
        showActionSalvar: Boolean
    },
    components: {
        ValidationObserver
    },
    data: () => ({
        snackbar: false,
        snackbarColor: 'green white--text',
        mensagem: null,
        mensagemAviso: null,
        wordsRules: [v => v.trim().split(' ').length <= 5 || 'Max 5 words'],
        value: 'Esse é um SMS exemplo, onde informa os detalhes da oferta',
        menu1: null,
        menu2: null,
        passo1: false,
        passo2: false,
        passo3: false,
        passo4: false,
        ativaDescontoPorcentagem: null,
        ativaDescontoFixo: null,
        errorMessages: null,
        checkbox: null,
        items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
        enabled: false,
        modalTemCerteza: false,
        liConcordo: false,
        loading: false
    }),
    methods: {
        modalDesabilitaOferta() {
            this.modalTemCerteza = true;
        },
        desabilitarOferta() {
            this.loading = true;
            let ofertaId = this.oferta.id;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.desabilitarOfertaGlobal(ofertaId).then(
                response => {
                    this.loading = false;
                    this.modalTemCerteza = false;
                    if (response.status == 200) {
                        this.$emit('fecharModalSuccess');
                    } else {
                        this.mensagemAviso =
                            'Não foi possível desabilitar, tente novamente';
                        this.snackbarColor = 'error';
                        this.snackbar = true;
                        this.$emit('fecharModalError');
                    }
                },
                error => {
                    this.mensagem = error;
                    console.log(error);
                }
            );
        },
        habilitaSecao1() {
            this.passo1 = true;
        },
        habilitaSecao2() {
            this.passo2 = true;
        },
        habilitaSecao3() {
            this.passo3 = true;
        },
        startMessage() {
            if (!this.mensagem) {
                this.oferta.mensagem =
                    'acesse https://protestado.com.br para negociar suas pendências e regularizar seu nome!';
            }
            this.mensagem = `Olá ${this.getNomeCliente}, ${this.oferta.mensagem}`;
            return this.oferta.mensagem;
        },
        checkChar() {
            if (this.oferta.mensagem && this.oferta.mensagem <= 100) {
                return true;
            } else {
                return false;
            }
        },
        salvar() {
            this.$emit('actionSalvar');
        },
        formatDate(date) {
            if (!date) return null;
            if (date.includes('-')) {
                const [year, month, day] = date.split('-');
                return `${day}/${month}/${year}`;
            } else {
                return date;
            }
        },
        cartaoCheck() {
            this.errorMessages =
                this.oferta.cartao &&
                !this.computedJurosCartaoFormated &&
                !this.oferta.cartao
                    ? `Hey! I'm required`
                    : '';
        },
        formatDinheiroString(valor) {
            return Formatter.toString(valor);
        },
        formatPorcentagem(valor) {
            return Formatter.toString(valor);
        },
        submit() {
            this.$refs.observer.validate();
        },
        descontoCheck() {
            if (
                this.oferta.desconto_porcentagem != null &&
                this.oferta.desconto_porcentagem != ''
            ) {
                this.oferta.desconto = null;
                return true;
            } else {
                return false;
            }
        },
        descontoCheckPorcentagem() {
            if (this.oferta.desconto != null && this.oferta.desconto != '') {
                this.oferta.desconto_porcentagem = null;
                return true;
            } else {
                return false;
            }
        },
        limparCartao() {
            if (this.oferta.cartao === false) {
                this.oferta.juros_cartao = null;
            }
            if (this.oferta.cartao === false) {
                this.oferta.parcela_cartao = null;
            }
        },
        limparBoleto() {
            if (this.oferta.boleto === false) {
                this.oferta.juros_boleto = null;
            }
            if (this.oferta.boleto === false) {
                this.oferta.parcela_boleto = null;
            }
        }
    },
    mounted() {
        if (this.activeIdOffer == true) {
            this.passo1 = true;
            this.passo2 = true;
            this.passo3 = true;
        }
    },
    computed: {
        computedDateFormattedInicio: {
            get: function() {
                return this.formatDate(this.oferta.data_inicio);
            },
            set: function(valor) {
                this.oferta.data_inicio = valor;
            }
        },
        computedDateFormattedFim: {
            get: function() {
                return this.formatDate(this.oferta.data_fim);
            },
            set: function(valor) {
                this.oferta.data_fim = valor;
            }
        },
        computedValorDeFormated: {
            get: function() {
                return this.formatDinheiroString(this.oferta.valor_de);
            },
            set: function(valor) {
                this.oferta.valor_de = valor;
            }
        },
        computedValorAteFormated: {
            get: function() {
                return this.formatDinheiroString(this.oferta.valor_ate);
            },
            set: function(valor) {
                this.oferta.valor_ate = valor;
            }
        },
        computedDescontoFormated: {
            get: function() {
                return this.formatDinheiroString(this.oferta.desconto);
            },
            set: function(valor) {
                this.oferta.desconto = valor;
            }
        },
        computedPorcentagemDescontoFormated: {
            get: function() {
                return this.formatPorcentagem(this.oferta.desconto_porcentagem);
            },
            set: function(valor) {
                this.oferta.desconto_porcentagem = valor;
            }
        },
        computedJurosCartaoFormated: {
            get: function() {
                return this.formatPorcentagem(this.oferta.juros_cartao);
            },
            set: function(valor) {
                this.oferta.juros_cartao = valor;
            }
        },
        computedJurosBoletoFormated: {
            get: function() {
                return this.formatPorcentagem(this.oferta.juros_boleto);
            },
            set: function(valor) {
                this.oferta.juros_boleto = valor;
            }
        },
        computedDescontoCheck() {
            return this.descontoCheck();
        },
        computedMessageFormated: {
            get: function() {
                return this.startMessage();
            },
            set: function(valor) {
                this.oferta.mensagem = valor;
                this.mensagem = `Olá ${this.getNomeCliente}, ${valor}`;
            }
        },
        computedDescontoCheckPorcentagem() {
            return this.descontoCheckPorcentagem();
        },
        getNomeCliente() {
            return 'Cliente Exemplo';
        }
    }
};
</script>

<style scoped>
#margin-dialog-body {
    margin-top: 15px;
    margin-right: 15px;
    margin-bottom: 15px;
    margin-left: 0px;
}
</style>
