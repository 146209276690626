<template>
    <div>
        <!-- Cabeçalho -->
        <div align="center" justify="center" class="ma-3">
            <v-card align="left" justify="left" class="">
                <v-card-text class="pa-3 font-weight-light white black--text">
                    <v-icon class="primary--text pa-2">
                        mdi-handshake-outline </v-icon
                    >Renegociação > Listar Renegociações ativas
                </v-card-text>
            </v-card>
            <v-card
                class="my-3 pa-3"
                color="primary"
                style="border-radius: 20px;"
            >
                <v-row>
                    <v-col cols="3" xs="3" md="3" class="ma-3 pa-3">
                        <v-icon
                            class="white--text ma-6 pa-6 mx-auto my-auto"
                            size="110"
                            elevation-8
                        >
                            mdi-handshake-outline
                        </v-icon>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="12"
                        md="5"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text class="white--text display-1">
                            Gerenciar <strong>Renegociações</strong> de Títulos
                            <v-chip
                                class="ma-2"
                                color="secondary"
                                label
                                text-color="white"
                            >
                                <v-icon left>
                                    mdi-label
                                </v-icon>
                                Novidade!
                            </v-chip>
                        </v-card-text>
                    </v-col>
                    <v-col
                        sm="12"
                        md="2"
                        class="ma-3 pa-3 mx-auto my-auto"
                        align="left"
                        justify="left"
                    >
                        <v-card-text
                            class="
                                white--text
                                font-weight-light
                                subtitle
                                mb-3
                                pa-3
                            "
                        >
                            Gerencie renegociações de pagamentos realizados
                            através da Plataforma Protestado
                        </v-card-text>
                    </v-col>
                </v-row>

                <v-card class="mt-sm-n8 ma-6" elevation-0 outlined>
                    <v-expansion-panels>
                        <v-expansion-panel>
                            <v-expansion-panel-header class="grey lighten-4">
                                <v-card-text
                                    class="font-weight-light black--text"
                                >
                                    <v-icon class="primary--text lighten-2">
                                        search
                                    </v-icon>
                                    Filtrar Resultados
                                </v-card-text>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row class="my-4">
                                    <v-col
                                        cols="9"
                                        sm="12"
                                        md="12"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-select
                                            solo
                                            small
                                            v-model="filtros.formaPagamento"
                                            :items="formasDePagamento"
                                            label="Forma de Pagamento"
                                            clearable
                                            item-text="label"
                                            item-value="value"
                                            :return-object="true"
                                        ></v-select>
                                    </v-col>
                                    <v-tooltip right>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                                class="my-auto"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Buscar por Valor Inicial: utilize
                                            esse campo para fazer busca a partir
                                            do valor inicial.Lembrando</span
                                        >
                                    </v-tooltip>
                                    <v-col
                                        cols="9"
                                        xs="12"
                                        sm="12"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-select
                                            solo
                                            small
                                            v-model="filtros.statusPagamento"
                                            :items="statusPagamento"
                                            label="Status do Pagamento"
                                            clearable
                                            item-text="label"
                                            item-value="value"
                                            :return-object="true"
                                        ></v-select>
                                    </v-col>
                                    <v-tooltip right>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Buscar por Valor Final: utilize
                                            esse campo para fazer busca a partir
                                            do valor inicial</span
                                        >
                                    </v-tooltip>

                                    <v-col
                                        cols="6"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class="mx-2"
                                    >
                                        <v-menu
                                            ref="menu1"
                                            v-model="menu1"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    solo
                                                    v-model="
                                                        computedDateFormattedInicio
                                                    "
                                                    label="Última atualização de:"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    placeholder="Selecione no Calendário"
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    clearable
                                                    @change="limparDataInicio"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="filtros.data_inicio"
                                                no-title
                                                scrollable
                                                locale="pt-br"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu1 = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.menu1.save(
                                                            filtros.data_inicio_fmt
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-tooltip left>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2
"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Atualização: Selecione a data
                                            inicial da renegociação que deseja
                                            encontrar, após selecionar clique em
                                            OK</span
                                        >
                                    </v-tooltip>
                                    <v-col
                                        cols="6"
                                        xs="2"
                                        sm="2"
                                        md="3"
                                        lg="3"
                                        class="mx-2"
                                    >
                                        <v-menu
                                            ref="menu2"
                                            v-model="menu2"
                                            :close-on-content-click="false"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="290px"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-text-field
                                                    v-model="
                                                        computedDateFormattedFim
                                                    "
                                                    label="Última atualização até:"
                                                    prepend-icon="mdi-calendar"
                                                    readonly
                                                    v-bind="attrs"
                                                    placeholder="Selecione no Calendário"
                                                    v-on="on"
                                                    clearable
                                                    solo
                                                    @change="limparDataFim"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                v-model="filtros.data_fim"
                                                no-title
                                                scrollable
                                                locale="pt-br"
                                            >
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="menu2 = false"
                                                >
                                                    Cancel
                                                </v-btn>
                                                <v-btn
                                                    text
                                                    color="primary"
                                                    @click="
                                                        $refs.menu2.save(
                                                            filtros.data_fim_fmt
                                                        )
                                                    "
                                                >
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-tooltip left>
                                        <template
                                            v-slot:activator="{ on, attrs }"
                                        >
                                            <v-icon
                                                color="grey lighten-2"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                help
                                            </v-icon>
                                        </template>
                                        <span
                                            >Atualização: Selecione a data final
                                            da renegociação que deseja
                                            encontrar, após selecionar clique em
                                            OK</span
                                        >
                                    </v-tooltip>
                                </v-row>
                                <v-row class="my-4">
                                    <v-col
                                        cols="9"
                                        sm="12"
                                        md="12"
                                        lg="2"
                                        class="mx-2"
                                    >
                                        <v-select
                                            solo
                                            small
                                            v-model="filtros.tipoOferta"
                                            :items="tipoOferta"
                                            label="Tipo de Oferta"
                                            clearable
                                            item-text="label"
                                            item-value="value"
                                            :return-object="true"
                                        ></v-select>
                                    </v-col>
                                    <v-col
                                        cols="7"
                                        xs="7"
                                        sm="4"
                                        md="4"
                                        lg="5"
                                        class="px-3 pt-0 pb-3"
                                        align="left"
                                    >
                                        <div
                                            class="font-weight-bold text-h6"
                                            style="color: #6c6c6c"
                                        >
                                            Data de atualização do Título
                                        </div>
                                        <div
                                            class="font-weight-normal"
                                            style="color: #6c6c6c"
                                        >
                                            Através dos atalhos ao lado,
                                            selecione os títulos que se
                                            encontram dentro prazo de
                                            atualização disponível.
                                        </div>
                                    </v-col>
                                    <v-col
                                        cols="5"
                                        xs="5"
                                        sm="5"
                                        md="5"
                                        lg="4"
                                        align="center"
                                        class="d-flex flex-row justify-center align-center px-3 pt-0 pb-3"
                                    >
                                        <v-btn
                                            class="ma-1"
                                            color="primary"
                                            @click="setDataMovimentoFiltro(1)"
                                        >
                                            Até 1D
                                        </v-btn>
                                        <v-btn
                                            class="ma-1"
                                            color="primary"
                                            @click="setDataMovimentoFiltro(7)"
                                        >
                                            Até 7D
                                        </v-btn>
                                        <v-btn
                                            class="ma-1"
                                            color="primary"
                                            @click="setDataMovimentoFiltro(30)"
                                        >
                                            Até 30D
                                        </v-btn>
                                        <v-btn
                                            class="my-1 mr-0 ml-1"
                                            color="primary"
                                            @click="setDataMovimentoFiltro(90)"
                                        >
                                            Até 90D
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-card>
            </v-card>
            <v-card @keyup.native.enter="aplicarFiltros" class="pa-3">
                <v-row class="my-1">
                    <v-col cols="4" xs="2" sm="2" md="7" lg="7" class="mx-3">
                    </v-col>
                    <v-col
                        cols="11"
                        xs="6"
                        sm="6"
                        md="2"
                        lg="2"
                        align="center"
                        class="mx-2"
                    >
                    </v-col>
                    <v-col
                        cols="11"
                        xs="6"
                        sm="6"
                        md="2"
                        lg="2"
                        align="center"
                        class="mx-2"
                    >
                        <v-btn
                            width="180"
                            class="mt-n1 d-flex justify-center"
                            color="primary"
                            :loading="loadingFiltros"
                            @click="botaoFiltrar"
                        >
                            Buscar
                            <v-icon class="ml-2">search</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
            <!-- fim do cabeçalho -->
            <!-- Inicio tabela resultados -->
            <Snackbar
                v-if="snackbar"
                :mostrarSnackbar="snackbar"
                :corSnackbar="snackbarColor"
                :mensagemSnackbar="mensagemAviso"
                @fecharSnackbar="snackbar = false"
            />
            <v-data-table
                :headers="headers"
                :items="renegociacoes"
                item-key="renegociacoes"
                class="elevation-1 pa-3 mt-3"
            >
                <template v-slot:[`item.id`]="{ item }">
                    <span>
                        {{ item.id }}
                    </span>
                </template>
                <template v-slot:[`item.status`]="{ item }">
                    <span v-if="item.status == 'WAITING_PAYMENT'">
                        Aguardando Pagamento
                    </span>
                    <span v-if="item.status == 'DEBT_PAID_OFF'">
                        Pagamento Confirmado
                    </span>
                    <!-- <span v-else>
                        {{ item.status }}
                    </span> -->
                </template>
                <template v-slot:[`item.tipo_pagamento`]="{ item }">
                    <span v-if="item.tipo_pagamento === 'B'">
                        Boleto
                    </span>
                    <span v-if="item.tipo_pagamento === 'C'">
                        Cartão
                    </span>
                    <!-- <span v-else>
                        {{ item.tipo_pagamento }}
                    </span> -->
                </template>
                <template v-slot:[`item.tipo_oferta`]="{ item }">
                    <span v-if="item.tipo_oferta === 'A'">
                        Oferta Global
                    </span>
                    <span v-if="item.tipo_oferta === 'T'">
                        Oferta Título
                    </span>
                    <span v-else>
                        {{ item.tipo_oferta }}
                    </span>
                </template>
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{ formatDate(item.updated_at) }}
                </template>
                <template v-slot:[`item.id_titulo`]="{ item }">
                    <span class="d-flex">
                        <span class="d-flex justify-center align-center">
                            {{ item.id_titulo }}
                        </span>
                        <v-btn
                            x-small
                            elevation="0"
                            class="ma-3"
                            fab
                            :loading="loadingFiltros"
                            @click="exibirDetalheTitulo(item)"
                            ><v-icon>mdi-open-in-new</v-icon></v-btn
                        >
                    </span>
                </template>
                <template v-slot:[`item.oferta_origem`]="{ item }">
                    <span v-if="item.titulo_oferta" class="d-flex">
                        <span class="d-flex justify-center align-center">
                            {{ item.titulo_oferta }}
                        </span>
                        <v-btn
                            x-small
                            elevation="0"
                            class="ma-3"
                            fab
                            :loading="loadingFiltros"
                            @click="exibirDetalheOfertaTitulo(item)"
                            ><v-icon>mdi-open-in-new</v-icon></v-btn
                        >
                    </span>
                    <span v-if="item.apresentante_oferta" class="d-flex">
                        <span class="d-flex justify-center align-center">
                            {{ item.apresentante_oferta }}
                        </span>
                        <v-btn
                            x-small
                            elevation="0"
                            class="ma-3"
                            fab
                            :loading="loadingFiltros"
                            @click="exibirDetalheOfertaGlobal(item)"
                            ><v-icon>mdi-open-in-new</v-icon></v-btn
                        >
                    </span>
                </template>
                <template v-slot:[`item.valor`]="{ item }">
                    R$ {{ item.valor }}
                </template>
                <template v-slot:[`item.valor_pago`]="{ item }">
                    R$ {{ item.valor_pago }}
                </template>
                <template v-slot:[`item.cartao`]="{ item }">
                    <v-icon
                        :style="{ color: item.cartao != '' ? 'green' : 'red' }"
                        >{{ getBolean(item.cartao) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.juros_cartao`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_cartao) }}
                    </span>
                </template>
                <template v-slot:[`item.boleto`]="{ item }">
                    <v-icon
                        :style="{ color: item.boleto != '' ? 'green' : 'red' }"
                        >{{ getBolean(item.boleto) }}</v-icon
                    >
                </template>
                <template v-slot:[`item.juros_boleto`]="{ item }">
                    <span>
                        {{ formatPorcentagem(item.juros_boleto) }}
                    </span>
                </template>
                <!-- <template v-slot:[`item.action`]="{ item }">
                    <v-btn
                        small
                        elevation="1"
                        width="100%"
                        :loading="loadingFiltros"
                        @click="exibirDetalhe(item)"
                        class="mr-2 grey white--text"
                    >
                        <v-icon
                            small
                            class="mr-2"
                            @click="exibirDetalhe(item)"
                            :style="{ color: 'white' }"
                            >edit</v-icon
                        >
                        Editar
                    </v-btn>
                </template> -->
            </v-data-table>
            <!-- Fim tabela resultados -->
        </div>
        <v-dialog
            v-if="dialogTitulo"
            v-model="dialogTitulo"
            max-width="1200px"
            persistent
            scrollable
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Informações sobre o titulo -->
            <v-card>
                <v-card-title class="pa-0">
                    <v-toolbar flat dark color="primary">
                        <v-btn class="ml-0" icon dark @click="closeDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-toolbar-title>Detalhes do Título</v-toolbar-title>
                        <v-spacer />
                    </v-toolbar>
                </v-card-title>
                <v-divider />
                <v-card-text class="px-8 py-4">
                    <DetalheTitulo
                        :titulo="titulo"
                        @fecharModal="closeDialog"
                    />
                    <HistoricoTitulo
                        class="mt-3"
                        :historico_titulo="historicos"
                    />
                </v-card-text>
            </v-card>
            <!-- fim das informações sobre o titulo--------------------- -->
        </v-dialog>
        <v-dialog
            v-if="dialogOfertaTitulo"
            v-model="dialogOfertaTitulo"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre a oferta título -->

            <v-card>
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-col cols="12" sm="2" align="left">
                        <v-toolbar-title>Detalhe Oferta</v-toolbar-title>
                    </v-col>
                </v-toolbar>
                <v-card-text>
                    <DetalheOfertaTitulo
                        :oferta="oferta"
                        :activeIdOffer="activeIdOffer"
                        :setAtualizarTitulo="setAtualizarTitulo"
                        :mostrarConfirmacao="mostrarConfirmacao"
                        :habilitaCorrecao="habilitaCorrecao"
                        @fecharModal="closeDialog"
                        @showSnackbar="snackbarDetalheTitulo"
                        @atualizarTitulo="atualizarTitulo"
                    />
                </v-card-text>
                <v-container>
                    <v-row class="pa-2" align="baseline" justify="end">
                        <v-btn
                            class="ml-5"
                            v-if="oferta.id"
                            dense
                            color="grey white--text"
                            @click="closeDialog"
                        >
                            Fechar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
            <!-- fim das informações sobre a oferta titulo--------------------- -->
        </v-dialog>
        <v-dialog
            v-if="dialogOfertaGlobal"
            v-model="dialogOfertaGlobal"
            max-width="1200px"
            persistent
            color="background"
            transition="dialog-bottom-transition"
        >
            <!-- Innformações sobre a oferta título -->

            <v-card>
                <v-toolbar flat dark color="primary">
                    <v-btn icon dark @click="closeDialog">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>

                    <v-col cols="12" sm="2" align="left">
                        <v-toolbar-title>Detalhe Oferta</v-toolbar-title>
                    </v-col>
                </v-toolbar>
                <v-card-text>
                    <DetalheOfertaGlobal
                        :oferta="oferta"
                        :activeIdOffer="activeIdOffer"
                        :setAtualizarTitulo="setAtualizarTitulo"
                        :mostrarConfirmacao="mostrarConfirmacao"
                        :habilitaCorrecao="habilitaCorrecao"
                        @fecharModal="closeDialog"
                        @showSnackbar="snackbarDetalheTitulo"
                        @atualizarTitulo="atualizarTitulo"
                    />
                </v-card-text>
                <v-container>
                    <v-row class="pa-2" align="baseline" justify="end">
                        <v-btn
                            class="ml-5"
                            v-if="oferta.id"
                            dense
                            color="grey white--text"
                            @click="closeDialog"
                        >
                            Fechar
                        </v-btn>
                    </v-row>
                </v-container>
            </v-card>
            <!-- fim das informações sobre a oferta titulo--------------------- -->
        </v-dialog>
    </div>
</template>
<script>
import Vue from 'vue';
import { validationMixin } from 'vuelidate';
import * as Formatter from '@/helpers/Formatter';
import OfertaService from '@/services/OfertaService';
import Renegociacao from '@/domains/Renegociacao';
import OfertaTitulo from '@/domains/OfertaTitulo';
import { required, email, max } from 'vee-validate/dist/rules';
import { extend, setInteractionMode } from 'vee-validate';
import moment from 'moment';
import DetalheOfertaTitulo from '@/components/DetalheOfertaTitulo';
import DetalheOfertaGlobal from '@/components/DetalheOfertaGlobal';
import TituloService from '@/services/TituloService';
import Titulo from '@/domains/Titulo';
import DetalheTitulo from '@/components/DetalheTitulo';
import HistoricoTitulo from '@/components/HistoricoTitulo';

setInteractionMode('eager');

extend('required', {
    ...required,
    message: '{_field_} can not be empty'
});

extend('max', {
    ...max,
    message: '{_field_} may not be greater than {length} characters'
});

extend('email', {
    ...email,
    message: 'Email must be valid'
});

export default {
    name: 'ListarRenegociacoes',
    mixins: [validationMixin],

    components: {
        DetalheOfertaTitulo,
        DetalheOfertaGlobal,
        DetalheTitulo,
        HistoricoTitulo
    },

    validations: {
        checkbox: {
            checked(val) {
                return val;
            }
        }
    },

    data() {
        return {
            statusPagamento: [
                { value: '', label: 'Todos' },
                { value: 'DEBT_PAID_OFF', label: 'Pagamento Confirmado' },
                { value: 'WAITING_PAYMENT', label: 'Aguardando Pagamento' }
            ],
            titulo: null,
            historicos: [],
            formasDePagamento: [
                { value: '', label: 'Todos' },
                { value: 'C', label: 'Cartão' },
                { value: 'B', label: 'Boleto Bancário' }
            ],
            tipoOferta: [
                { value: '', label: 'Todos' },
                { value: 'T', label: 'Oferta Título' },
                { value: 'A', label: 'Oferta Global' }
            ],
            dialogOfertaTitulo: false,
            dialogOfertaGlobal: false,
            oferta: new OfertaTitulo(),
            activeIdOffer: false,
            snackbar: false,
            snackbarColor: 'green white--text',
            mensagem: null,
            renegociacoes: [],
            dialogInicio: false,
            filtros: {
                statusPagamento: null,
                formaPagamento: null,
                tipoOferta: null,
                data_inicio: this.computedDateFormattedInicio,
                data_fim: this.computedDateFormattedFim
            },
            loadingFiltros: false,
            dialogTitulo: false,
            data_inicio: null,
            data_fim: null,
            ready: false,
            menu1: false,
            menu2: false,
            modal: false,
            modal2: false,
            cartao: true,
            boleto: true,
            status: true,
            id: null,
            ultimoFiltro: null,
            pagina: 1,
            totalRenegociacoes: 0,
            showPagination: false,
            renegociacao: new Renegociacao(),
            mensagemAviso: null,
            headers: [
                {
                    text: 'Id',
                    sortable: false,
                    value: 'id'
                },
                {
                    text: 'Id Título',
                    sortable: false,
                    value: 'id_titulo'
                },
                {
                    text: 'Título',
                    sortable: false,
                    value: 'titulo'
                },
                {
                    text: 'Status de Pagamento',
                    sortable: false,
                    value: 'status'
                },
                {
                    text: 'Forma de Pagamento',
                    sortable: false,
                    value: 'tipo_pagamento'
                },
                {
                    text: 'Valor Original',
                    sortable: false,
                    value: 'valor'
                },
                {
                    text: 'Valor Pago',
                    sortable: false,
                    value: 'valor_pago'
                },
                {
                    text: 'Atualização',
                    sortable: false,
                    value: 'updated_at'
                },
                {
                    text: 'Tipo de Oferta',
                    sortable: false,
                    value: 'tipo_oferta'
                },
                {
                    text: 'Oferta',
                    sortable: false,
                    value: 'oferta_origem'
                },
                { text: ' ', sortable: false, value: 'action' }
            ]
        };
    },

    computed: {
        computedDateFormattedInicio: {
            get: function() {
                if (this.filtros.data_inicio) {
                    return this.formatDate(this.filtros.data_inicio);
                } else {
                    return this.formatDate(this.data_inicio);
                }
            },
            set: function(value) {
                this.filtros.data_inicio = value;
            }
        },
        computedDateFormattedFim: {
            get: function() {
                if (this.filtros.data_fim) {
                    return this.formatDate(this.filtros.data_fim);
                } else {
                    return this.formatDate(this.data_fim);
                }
            },
            set: function(value) {
                this.filtros.data_fim = value;
            }
        }
    },

    methods: {
        formatDate(item) {
            let dataFormatada;
            if (item) {
                dataFormatada = moment(item).format('DD/MM/YYYY');
            } else {
                dataFormatada = '--';
            }
            return dataFormatada;
        },
        closeDialog() {
            this.dialogTitulo = false;
            this.dialogOfertaTitulo = false;
            this.dialogOfertaGlobal = false;
            this.renegociacao = null;
            this.renegociacao = [];
            this.activeIdOffer = false;
            this.botaoFiltrar();
        },
        dialogInicioOpen() {
            this.dialogInicio = true;
        },
        closeDialogInicio() {
            this.dialogInicio = false;
        },
        botaoFiltrar() {
            if (this.pagina == 1) {
                this.aplicarFiltros();
            } else {
                this.pagina = 1;
            }
        },
        exibirDetalheTitulo(titulo) {
            this.loadingFiltros = true;
            this.titulo = new Titulo();
            let tituloService = new TituloService(Vue.http, this.$store);
            tituloService
                .detalharTitulo(titulo)
                .then(
                    response => {
                        const {
                            detalhe_titulo,
                            historico_titulo
                        } = response.body;
                        this.titulo = detalhe_titulo;
                        this.historicos = historico_titulo;
                        this.dialogTitulo = true;
                    },
                    error => {
                        this.mensagem = error;
                        console.log(error);
                    }
                )
                .finally(() => {
                    this.loadingFiltros = false;
                });
        },
        limparDataInicio() {
            this.renegociacao.data_inicio = [];
        },
        limparDataFim() {
            this.renegociacao.data_fim = [];
        },
        aplicarFiltros() {
            this.ultimoFiltro = {};
            if (this.filtros.statusPagamento) {
                this.ultimoFiltro.statusPagamento = this.filtros.statusPagamento.value;
            }
            if (this.filtros.formaPagamento) {
                this.ultimoFiltro.formaPagamento = this.filtros.formaPagamento.value;
            }
            if (this.filtros.tipoOferta) {
                this.ultimoFiltro.tipoOferta = this.filtros.tipoOferta.value;
            }
            if (this.filtros.data_inicio) {
                this.ultimoFiltro.data_inicio = this.filtros.data_inicio;
            }
            if (this.filtros.data_fim) {
                this.ultimoFiltro.data_fim = this.filtros.data_fim;
            }
            this.buscarRenegociacoes();
        },
        buscarRenegociacoes() {
            return new Promise((resolve, reject) => {
                this.loadingFiltros = true;
                this.loading = true;
                let params = this.ultimoFiltro;
                try {
                    let service = new OfertaService(Vue.http, this.$store);

                    service.buscarRenegociacoes(params).then(
                        response => {
                            this.renegociacoes = [];
                            this.renegociacoes = response.data;
                            this.loadingFiltros = false;
                            this.loading = false;
                        },
                        error => {
                            this.loadingFiltros = false;
                            this.loading = false;
                            this.mensagem = error;
                            console.log(error);
                        }
                    );
                } catch (e) {
                    this.mensagem = e;
                    console.log(e);
                    reject();
                }
            });
        },
        formatDinheiroString(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return 'R$ ' + Formatter.toString(valor);
            }
        },
        formatPorcentagem(valor) {
            if (valor == null) {
                return 'Não selecionado';
            } else {
                return Formatter.toString(valor) + '%';
            }
        },
        getBolean(valor) {
            if (valor) {
                return 'done';
            } else {
                return 'close';
            }
        },
        exibirDetalheOfertaTitulo(renegociacao) {
            this.loadingFiltros = true;
            let ofertaSelecionada = renegociacao.titulo_oferta;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.exibirDetalheOfertaTitulo(ofertaSelecionada).then(
                response => {
                    this.oferta = response.body[0];
                    this.activeIdOffer = true;
                    this.dialogOfertaTitulo = true;
                    this.loadingFiltros = false;
                },
                error => {
                    this.mensagemAviso = error;
                    console.log(error);
                }
            );
        },
        exibirDetalheOfertaGlobal(renegociacao) {
            this.loadingFiltros = true;
            let ofertaSelecionada = renegociacao.apresentante_oferta;
            let ofertaService = new OfertaService(Vue.http, this.$store);
            ofertaService.exibirDetalheOfertaGlobal(ofertaSelecionada).then(
                response => {
                    this.oferta = response.body[0];
                    this.activeIdOffer = true;
                    this.dialogOfertaGlobal = true;
                    this.loadingFiltros = false;
                },
                error => {
                    this.mensagemAviso = error;
                    console.log(error);
                }
            );
        }
    },
    mounted() {}
};
</script>
